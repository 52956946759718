import React, {Fragment} from 'react';
import {Button, Card, Loader, Search} from 'semantic-ui-react'
import { Country } from '../../../common/types/country';
import moment from 'moment';
import {
    fetchGroups,
    fetchSports,
    fetchCountries,
    fetchCompetitions,
    deleteGroup, restoreGroupRoutine
} from '../../../redux/routines';
import { connect } from 'react-redux';
import './style.scss';
import { IMainInfoProps, IRestrictionProps } from './interfaces/creation-form';
import GroupModal from './group-modal/group-modal';
import { history } from '../../../utils/history';
import { DeleteModal } from '../user-management/delete-confirmation-modal/delete-confirmation-modal';
import {Group, Groups} from '../../../common/types/group';
import { State } from '../../../common/types/state';
import {downloadGroupInfo} from "../../../services/groups.service";
import {User} from "../../../common/types/user";

const defaultMainInfo: IMainInfoProps = {
    name: '',
    contact: '',
    addresses: [],
    email: '',
    numberOfLicences: 20,
    picture: '',
    disabled: false,
    isTrial: false,
    trial: {
        startTime: moment(),
        endTime: moment()
    },
    clientAdminEmail: '',
}

const defaultRestrictions: IRestrictionProps = {
    isHdPlayerEnabled: false,
    isHdPlayerPaywallEnabled: false,
    isSdPlayerEnabled: false,
    isSdPlayerPaywallEnabled: false,
    isHdHlsEnabled: false,
    isHdHlsPaywallEnabled: false,
    isSdHlsEnabled: false,
    isSdHlsPaywallEnabled: false,
    isHdVodEnabled: false,
    isHdVodPaywallEnabled: false,
    isSdVodEnabled: false,
    isSdVodPaywallEnabled: false,
    isTrial: false,
    isMaxPlayersEnabled: false,
    isRtmpEnabled: false,
    isDocumentsDownloadEnabled: false,
    isStreamsDownloadEnabled: false,
    isSPRAVEnabled: false,
    isRunningBallEnabled: false,
    isStatsPerformContentEnabled: false,
    isInPlayContentEnabled: false,
    isXVIContentEnabled: false,
    isModusContentEnabled: false,
    isInternalScheduleContentEnabled: false,
    isStatsPerformPaywallEnabled: false,
    isSPRAVPaywallEnabled: false,
    isInPlayPaywallEnabled: false,
    isXVIPaywallEnabled: false,
    isModusPaywallEnabled: false,
    isInternalSchedulePaywallEnabled: false,
    isSpringMediaContentEnabled: false,
    isSpringMediaPaywallEnabled: false,
    isInfrontContentEnabled: false,
    isInfrontPaywallEnabled: false,
    isCSMContentEnabled: false,
    isCSMPaywallEnabled: false,
    isTDIContentEnabled: false,
    isTDIPaywallEnabled: false,
    isTrintaContentEnabled: false,
    isTrintaPaywallEnabled: false,
    isBOXXERContentEnabled: false,
    isBOXXERPaywallEnabled: false,
    isSailGPContentEnabled: false,
    isSailGPPaywallEnabled: false,
    is24HrEnabled: false,
    isCustomScheduleEnabled: false,
    isScheduleApiStreamDownloadEnabled: false,
    isVodClippingEnabled: false,
    disabledSports: [],
    disabledCompetitions: [],
    trial: {
        startTime: moment(),
        endTime: moment().add('14', 'd'),
    },
    isDomainOnlyEnabled: false,
    domainsWithAccess: "",
    isRequiredSport: false,
    isRequiredCompetition: false,
    isSkyBoxesAllowed: false,
    isInternalStreamsAllowed: false,
    devicesAllowedEnabled: false,
    devicesCount: 0,
    latencyToleranceMs: 1250,
    bufferingMs: 1250
}

export interface IGroupManagementPageState {
    formPage: number;
    groups: Group[];
    focusedInput: HTMLInputElement | null;
    groupToDelete?: Group;
    itemToUpdate?: any;
    isCreationModelOpened: boolean;
    tabIndex: number;
    searchWord: string;
    searchLoading: boolean;
    searchedGroups: Group[];
}

export interface IGroupManagementPageProps {
    fetchGroups: () => void;
    fetchCompetitions: () => void;
    fetchCountries: () => void;
    fetchSports: () => void;
    deleteGroup: (x: { groupId: string, reason: string, hardDelete: boolean }) => void;
    sports: string[];
    competitions: string[];
    countries: Country[];
    groups: Groups;
    currentUser: User;
    restoreGroup: (userId: number) => void;
}

class GroupManagementPage extends React.Component<IGroupManagementPageProps, IGroupManagementPageState> {
    constructor(props: any) {
        super(props);
        this.state = {
            formPage: 0,
            groups: [],
            focusedInput: null,
            groupToDelete: undefined,
            isCreationModelOpened: false,
            tabIndex: 0,
            searchWord: "",
            searchLoading: false,
            searchedGroups: [],
        }
    }

    componentDidMount() {
        const { fetchSports, fetchGroups, fetchCompetitions, fetchCountries } = this.props;

        fetchGroups();
        if (this.props.competitions.length === 0) {
            fetchCompetitions();
        }
        if (this.props.competitions.length === 0) {
            fetchCountries();
        }
        if (this.props.competitions.length === 0) {
            fetchSports();
        }
    }

    componentWillReceiveProps(nextProps: Readonly<IGroupManagementPageProps>) {
        if (!nextProps.groups.isLoading && !nextProps.groups.exception && (this.state.itemToUpdate || this.state.isCreationModelOpened)) {
            this.setState({
                isCreationModelOpened: false,
                itemToUpdate: undefined
            })
        }
    }

    deleteGroup(x: any) {
        var groupId = x.id;
        var reason = x.reason;
        var hardDelete = x.hardDelete;
        const { deleteGroup } = this.props;

        deleteGroup({ groupId, reason, hardDelete });
        this.setState({ groupToDelete: undefined })
    }

    openCreateModal() {
        this.setState({ tabIndex: 0 });
        const { isCreationModelOpened } = this.state;
        this.setState({
            isCreationModelOpened: !isCreationModelOpened
        })
    }

    clearItemToUpdate() {
        this.setState({
            itemToUpdate: undefined
        })
    }

    openDeleteModal(item) {
        this.setState({ groupToDelete: item })
    }

    onGroupRestore = (id) => {
        this.props.restoreGroup(id);
    }

    setTabIndex = (index: number) => {
        this.setState({ tabIndex: index });
    }

    setCardClass = (item) => {
        if (item.isDeleted) {
            return "card-deleted";
        } if (item.trialExpired) {
            return "card-trial-expired";
        } else {
            return "";
        }
    }

    handleSearchChange = (e, value) => {
        this.setState({ searchWord: value });
        this.setState({ searchLoading: true });

        setTimeout(() => {
            this.setState({ searchLoading: false });
            this.setState({
                searchedGroups: Array.isArray(this.props.groups.source)
                    ? this.props.groups.source.filter(i =>
                        i.name.toLowerCase().includes(value.toLowerCase())
                        || i.clientAdminEmail.toLowerCase().includes(value.toLowerCase())
                        || i.userEmails.some(x => x.toLowerCase().includes(value.toLowerCase()))
                    )
                    : []
            });
        }, 500);
    }

    renderCard = (item, groupToDelete) => {
        if (typeof item.restriction === 'undefined') {
            item.restriction = {};
        }
        if (item.restriction.isTrial) {
            item.restriction.trial!.startTime = moment(item.restriction.trial.startTime);
            item.restriction.trial!.endTime = moment(item.restriction.trial.endTime);
        }

        const link = `/group-management/${item.id}`;
        const restrictionWithDefaultTrial = item.restriction;

        restrictionWithDefaultTrial.trial = {
            startTime: moment(),
            endTime: moment().add('14', 'd'),
        }

        return (
            <Card className={this.setCardClass(item)} key={item.id} onClick={() => !item.isDeleted && history.push(link)}>
                <Card.Content>
                    <Card.Header>{item.name}</Card.Header>
                    <Card.Meta>
                        <p className='date' style={{width: 260, overflow: "hidden", textOverflow: "wrap"}}>Admin: {item.clientAdminEmail}</p>
                    </Card.Meta>
                    <Card.Description>
                        Licenses: {item.usedLicences}/{item.licensesCount}
                    </Card.Description>
                    <Card.Content extra>
                        <div className='card-controls' onClick={event => event.stopPropagation()}>
                            {item.isDeleted ?
                                <Button color='grey' onClick={() => this.onGroupRestore(item.id)}>
                                    Restore
                                </Button> :
                                <>
                                    <Button onClick={() => {
                                        this.setState({
                                            itemToUpdate: item
                                        })
                                    }}
                                            color='yellow'>
                                        Update
                                    </Button>
                                    <Button color='red' onClick={() => this.openDeleteModal(item)}>
                                        Delete
                                    </Button>
                                </>
                            }
                            <DeleteModal
                                actionType={'Delete'}
                                removeOrRestoreUserAction={(id) => console.log(id)}
                                removeUserAction={(id) => console.log(id)}
                                removeGroup={this.deleteGroup.bind(this)}
                                closeModal={() => { this.setState({ groupToDelete: undefined }) }}
                                object={groupToDelete}
                                type='Group'
                                modalOpened={!!groupToDelete && groupToDelete === item} />
                        </div>
                    </Card.Content>
                </Card.Content>
            </Card>
        )
    }

    // TODO: We need to rework this entirely.
    renderCards = () => {
        const { groups: { isLoading, source: groups } } = this.props;
        const { groupToDelete, searchWord, searchedGroups } = this.state;
        const trialsExist = Array.isArray(groups) && groups.some(x => x.isTrial);
        const onlyTrials = Array.isArray(groups) && groups.every(x => x.isTrial);
        const groupsToShow = searchWord.length > 0 ? searchedGroups : groups;

        return <>
            <h2 className="cards-trial-header">Trial</h2>
            {
                !trialsExist && <p className="empty">Category is empty...</p>
            }
                <Card.Group>
                    {
                        Array.isArray(groupsToShow) && groupsToShow.filter(x => x.isTrial).sort().map((item: any) => {

                            return this.renderCard(item, groupToDelete)
                        })
                    }
                </Card.Group>
            <div className="divider"></div>
            {
                onlyTrials && <p className="empty">Category is empty...</p>
            }
            {
                <Card.Group>
                    {
                        !isLoading && Array.isArray(groupsToShow) ? groupsToShow.filter(x => !x.isTrial).sort().map((item: any) => {

                            return this.renderCard(item, groupToDelete)

                        }) : <Loader active className='loader'/>
                    }
                    {
                        !isLoading &&
                        <Card onClick={this.openCreateModal.bind(this)}>
                            <div className="group-management_add-card">
                                <span>+</span>
                            </div>
                        </Card>
                    }
                </Card.Group>
            }
        </>
    }

    render() {
        const { itemToUpdate, isCreationModelOpened } = this.state;
        const restrictionWithDefaultTrial = itemToUpdate ? itemToUpdate.restriction : null;
        if (restrictionWithDefaultTrial) {
            restrictionWithDefaultTrial.trial = {
                startTime: moment(),
                endTime: moment().add('14', 'd'),
            }
        }

        const defaultTrial = itemToUpdate && itemToUpdate.restriction.trial ? itemToUpdate.restriction : restrictionWithDefaultTrial

        return (
            <div className="group-management_container">
                <div className="group-management_header">
                    Client Management Page
                    <Button onClick={() => downloadGroupInfo()}>Download CSV</Button>
                </div>
                <Search
                    loading={this.state.searchLoading}
                    onSearchChange={(e, data) => this.handleSearchChange(e, data.value)}
                    results={[]}
                    value={this.state.searchWord}
                />
                <div className="group-management_body">
                    <div style={{marginBottom: 15}}>
                        {
                            this.renderCards()
                        }
                        {
                            <Fragment>
                                {
                                    isCreationModelOpened &&
                                    <GroupModal
                                        id={0}
                                        isOpened={isCreationModelOpened}
                                        isCreationModal={true}
                                        mainInfo={{...defaultMainInfo}}
                                        restrictions={{...defaultRestrictions}}
                                        clearParentState={this.openCreateModal.bind(this)}
                                        itemToUpdate={this.state.itemToUpdate}
                                        tabIndex={this.state.tabIndex}
                                        setTabIndex={this.setTabIndex}
                                    />
                                }
                                {
                                itemToUpdate && itemToUpdate.id &&
                                <GroupModal
                                    isCreationModal={false}
                                    id={itemToUpdate.id}
                                    isOpened={true}
                                    mainInfo={{
                                        email: itemToUpdate.email,
                                        name: itemToUpdate.name,
                                        numberOfLicences: itemToUpdate.licensesCount,
                                        contact: itemToUpdate.contact,
                                        addresses: itemToUpdate.addresses,
                                        picture: itemToUpdate.picture,
                                        disabled: itemToUpdate.disabled,
                                        isTrial: itemToUpdate.isTrial,
                                        trial: itemToUpdate.trial,
                                        clientAdminEmail: itemToUpdate.clientAdminEmail,
                                    }}
                                    restrictions={defaultTrial}
                                    clearParentState={this.clearItemToUpdate.bind(this)}
                                    itemToUpdate={this.state.itemToUpdate}
                                    tabIndex={this.state.tabIndex}
                                    setTabIndex={this.setTabIndex}
                                />
                            }
                            </Fragment>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: State) => {
    return {
        groups: state.groups,
        currentUser: state.user,
        countries: state.countries,
        sports: state.sports,
        competitions: state.competitions
    }
}

const mapDispatchToProps = {
    fetchSports,
    fetchCountries,
    fetchCompetitions,
    fetchGroups,
    deleteGroup,
    restoreGroup: restoreGroupRoutine
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupManagementPage)

